<mat-toolbar color="primary" class="sticky">
  <mat-toolbar-row class="progress-row" fxLayout="row" fxLayoutAlign="space-between center" (click)="redirectToProfile()">
    <div class="thumbnail">
      <div #pixiAnimation class="animation"></div>
    </div>
    <div class="progress-bar-wrapper" fxLayout="column" fxLayoutAlign="center stretch" >
      <div fxLayout="row" fxLayoutAlign="space-between">
          <span class="info-text">Lvl {{(gamificationIndex$ | async)}}</span>
          <span class="info-text">Lvl {{(gamificationIndex$ | async) + 1}}</span>
      </div>
      <div class="progress-bar-container">
        <ng-container *ngIf="(progressPercentage$ | async) as progress">
          <div class="progress-bar-empty" [style.width.%]="100">
            <div class="progress-bar" [style.width.%]="progress"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="(progressPercentage$ | async) as progress">
      <p class="progress-text">{{progress}}%</p>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>

