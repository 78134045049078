import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PreloadService {

    constructor(
        // private chargeBackService: ChargebackService
    ) {
    }

    updateStore(username: string) {
        // this.bonusListService.updateStore();
        // this.loginStatusService.getLoginStatus().pipe(take(1)).subscribe(resp => {
        //     this.paymentListService.updateStore(resp.fullInfo.country.toLowerCase());
        // })

        // this.chargeBackService.updateStore(username);
    }
}
