import { Inject, Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import {HOST_NAME, LICENCE} from 'src/app/app-routing.module';
import { LogExceptionService } from '../utils/log-exception.service';
import {getApp} from '@angular/fire/app';
import {initializeFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BaseFirebaseService {
  protected licence: string;

  constructor(
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    @Inject(HOST_NAME) public hostName
  ) {
    this.logExceptionService = inject(LogExceptionService);
    this.licence = inject(LICENCE);
    BaseFirebaseService.applyFirebaseConfig(this.afs, this.licence);
  }
  public static applyFirebaseConfig(afs: AngularFirestore, licence: string) {
    (afs.firestore as any)._delegate = initializeFirestore(getApp(), {}, (licence === 'bison_win') ? 'bisonwin' : environment.firebaseDatabaseName || '(default)');
  }

  private logExceptionService: LogExceptionService;

  apiUrl = environment.production ? environment.uiBackend + this.hostName.replace('cache.', '') : environment.uiBackend;

  public setApiUrl(apiUrl?: string) {
    this.apiUrl = apiUrl ? apiUrl : environment.production ? environment.uiBackend + this.hostName.replace('cache.', '') : environment.uiBackend;
  }

  get<T>(url: string, withCredentials: boolean = true): Observable<T> {
    return this.afAuth.idToken.pipe(
      take(1),
      switchMap((token) => {
        const httpOptionsDefault = {
          headers: token ? { "X-Firebase-ID-Token": token } : {},
          withCredentials: withCredentials,
          params: new HttpParams().set('t', new Date().getTime().toString())
        };
        return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })
      })
    )
  }

  protected getRequestHttpParams<T>(url: string, reqParams: HttpParams) {
    // const args = { params: reqParams };
    return this.afAuth.idToken.pipe(
      take(1),
      switchMap((token) => {
        const httpOptionsDefault = {
          params: reqParams,
          headers: token ? { "X-Firebase-ID-Token": token } : {},
          withCredentials: true
        };
        return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })
      })
    )
  }

  post<T1, T2>(url: string, request: T1, withCredentials: boolean = true) {
    return this.afAuth.idToken.pipe(
      switchMap((token) => {
        const httpOptionsDefault = {
          headers: token ? { "X-Firebase-ID-Token": token } : {},
          withCredentials: withCredentials
        };
        return this.http.post<T2>(this.apiUrl + url, request, { ...httpOptionsDefault });
      })
    )
  }

  getDocumentDB<T>(path: string) {
    return this.afs.doc<T>(path).valueChanges().pipe(
      catchError(error => {

        return this.logExceptionService.logException(error, `${error.toString()}`, { path: path })
        // return of(error)
      }
      )
    );
  }

  getSnapshotDocument<T>(path: string) {
    return this.afs.doc<T>(path).snapshotChanges().pipe(
      catchError(error => {
        return this.logExceptionService.logException(error, `${error.toString()}`, { path: path })

      }
      )
    );;
  }

  addDocument<T>(document: T, path: string) {
    return this.afs.doc<T>(path).update(document)
  }

  setDocument<T>(document: T, path: string, isMerge: boolean) {
    return this.afs.doc<T>(path).set(document, { merge: isMerge })
  }
}
