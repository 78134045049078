<div class="search-result-warapper">
    <ng-container *ngIf="categoryList">
        <ng-container *ngFor="let category of categoryList">
            <ng-container *ngIf="category.displayName">
                <div class="search-result-container">
                    <div class="search-result-header">
                        <h2 [innerHTML]="category.displayName | removehtml"></h2>
    
                        <div class="search-result-action" (click)="onSelecEvent(category)">
                            <h3 i18n>All</h3>
                            <!-- <button class="all-btn"> -->
                                <!-- (click)="onCloseEvent()" -->
                                <img src="{{licenceService.getKey('icon-right')}}" icon-btn alt="close-btn" />
                            <!-- </button> -->
                        </div>
                    </div>
    
                    <div class="search-result-body">
                        <ng-container *ngFor="let game of category.game">
                            <app-search-game-result (openGameEvent)="onOpenGame($event)" [_game]="game"></app-search-game-result>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
