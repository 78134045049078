<div class="front-page-wrapper">
  <app-top-banner></app-top-banner>
  <!-- <ng-lottie class="animation-container" containerClass="succeeded-container" width="400px" height="auto"
      [options]="options"></ng-lottie> -->
  <div class="notification-sticker">
    <app-teaser-top-mobile *appShellNoRender></app-teaser-top-mobile>
  </div>

  <app-swiper-categories-template *appShellNoRender (categoryClick)="onSwiperCategoryClick()" />
  <ng-container #jackpots>
    <div *ngIf="isShowJackpotModulePlaceholder">
      <div style="height: 128px"></div>
    </div>
  </ng-container>
  <ng-container *loggedRender>
    <app-my-games-list></app-my-games-list>
  </ng-container>
  <ng-container>
    <app-recommendations></app-recommendations>
  </ng-container>
  <!-- <app-tournaments-carousel></app-tournaments-carousel> -->
  <ng-container #tournaments>
    <div *ngIf="isShowTournamentsModulePlaceholder">
      <div style="height: 128px"></div>
    </div>
  </ng-container>
  <ng-container [ngSwitch]="licence">
    <ng-container *ngSwitchCase="'bison_win'">
      <ng-container *ngTemplateOutlet="bison_win"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <app-game-category-short [data]="categories | slice : 0  : 1"></app-game-category-short>
      <ng-container #reactivation>
        <div *ngIf="isShowRectivationModulePlaceholder">
          <div style="height: 128px">
          </div>
        </div>
      </ng-container>
      <app-game-category-short [data]="categories | slice : 1  : 2"></app-game-category-short>
      <ng-container #missions>
        <div class="categoryNameContainer" fxLayout="row" fxLayoutAlign="center center">
          <h2 fxLayoutAlign="center center" class="category-title">
            <img class="category-icon" src="assets/playbison/icon-missions-home.svg" alt="mission-home-page-icon">
            <span i18n>Missions</span>
          </h2>
          <span class="spacer"></span>
          <a class="button-all-games" fxLayoutAlign="end center" fxLayout="row" [routerLink]="['/', missionsAllTrx]">
            <span i18n>All</span>
            <img src="{{licenceService.getKey('icon-right')}}" icon-btn size="large">
          </a>
        </div>
        <div *ngIf="isShowMissionsModulePlaceholder">
          <div style="height: 128px">
          </div>
        </div>
      </ng-container>
      <app-game-operator-short *ngIf="operators" [operators]="operators"></app-game-operator-short>
      <app-game-category-short [data]="categories | slice : 2"></app-game-category-short>
      <app-front-expansion-panel [data]="expansionPanel"></app-front-expansion-panel>
    </ng-container>
  </ng-container>

  <!-- <ng-container #reactivation>
    <div *ngIf="isShowRectivationModulePlaceholder">
      <div style="height: 128px">
      </div>
    </div>
  </ng-container> -->



</div>
<ng-container *ngIf="!detectDeviceService.isDesktop()">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #bison_win>
  <app-game-operator-short *ngIf="operators" [operators]="operators"></app-game-operator-short>
  <app-game-category-short [data]="categories | slice : 0  : 2"></app-game-category-short>
  <app-missions-carousel *appShellNoRender></app-missions-carousel>
  <app-game-category-short [data]="categories | slice : 2"></app-game-category-short>
  <app-front-expansion-panel [data]="expansionPanel"></app-front-expansion-panel>
</ng-template>