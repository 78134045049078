import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Optional, Output, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SearchConfigFilterItem, SearchGameService } from 'src/app/services/games/search-game.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { SearchGameComponent } from '../search-game.component';

@Component({
  selector: 'app-search-filter-sheet',
  templateUrl: './search-filter-sheet.component.html',
  styleUrls: ['./search-filter-sheet.component.scss']
})
export class SearchFilterSheetComponent extends BasePageComponent implements OnInit, AfterViewInit {

  public isSelected: boolean = false;
  public isDesktop: boolean;

  public filterItemDisplay: SearchConfigFilterItem;
  public filterItems: SearchConfigFilterItem[];

  public filterItemName: string;
  @Input() _filterItemName: string;
  
  @Output() closeEvent = new EventEmitter<null>();

  constructor(
    private searchGamesService: SearchGameService,
    public licenceService: LicenceService,
    public detectDeviceService: DetectDeviceService,
    private renderer: Renderer2,
    @Optional() public dialogRef: MatDialogRef<SearchGameComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public dialogData: any
  ) {
    super();

    this.isDesktop = detectDeviceService.isDesktop();
  }

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  ngOnInit(): void {
    this.filterItemName = this._filterItemName;
    window.scrollTo({ top: 0, behavior: 'auto' });

    this.searchGamesService.searchFilters$.pipe(takeUntil(this.unsubscribe)).subscribe((filterItems) => {
      this.filterItems = filterItems;
      this.filterItemDisplay = filterItems.find((filterItem) => filterItem.name === this.filterItemName); //  sort data in filterItemDisplay.data[array].name

      if (this.filterItemDisplay) this.filterItemDisplay.data.sort((a, b) => a.name.localeCompare(b.name));

      this.isSelected = this.checkIfAnyFilterItemSelected();
    });
  }

  ngAfterViewInit(): void {
    this.checkDialog();
  }

  checkDialog() {
    if (this.dialogRef) {
      const dialogContent = this.scrollContainer.nativeElement.closest('.mat-dialog-container');
      //console.log('dialogContent', dialogContent);

      if (dialogContent) {
        this.handlePosition(dialogContent);

        this.renderer.listen(dialogContent, 'scroll', () => {
          //console.log('Scroll event detected inside dialog');
          this.handleScroll(dialogContent);
        });
      }
    }
  }

  handlePosition(target: HTMLElement) {
    const searchWrapper = target.querySelector('.search-wrapper') as HTMLElement;
    const searchWrapperStyles = window.getComputedStyle(searchWrapper);
    target.scrollTop = 0;

    const searchFilterSheet = target.querySelector('.filter-sheet-wrapper') as HTMLElement;
    const filterSheetFooter = target.querySelector('.filter-sheet-footer') as HTMLElement;

    const searchWrapperPaddingTop = parseInt(searchWrapperStyles.getPropertyValue('padding-top'), 10);
    const searchWrapperPaddingBot = parseInt(searchWrapperStyles.getPropertyValue('padding-bottom'), 10);

    const dialogRect = target.getBoundingClientRect();
    //const dialogPosition = dialogRect.top;

    // console.log('dialog height', target.offsetHeight);
    // //console.log('searchWrapper height', searchWrapper.offsetHeight, 'searchWrapper padding', );
    // console.log('searchFilterSheet height', searchFilterSheet.offsetHeight);
    // console.log('filterSheetFooter height', filterSheetFooter.offsetHeight);
    // console.log('dialogRect top', dialogRect.top);
    // console.log('dialogRect bottom', dialogRect.bottom);

    console.log(target.offsetHeight, '>', searchFilterSheet.offsetHeight + filterSheetFooter.offsetHeight + searchWrapperPaddingTop + searchWrapperPaddingBot)
    if ( target.offsetHeight > searchFilterSheet.offsetHeight + filterSheetFooter.offsetHeight + searchWrapperPaddingTop + searchWrapperPaddingBot ) {
      this.renderer.setStyle(filterSheetFooter, 'position', 'relative');
      this.renderer.setStyle(filterSheetFooter, 'bottom', '0');
      //this.renderer.setStyle(filterSheetFooter, 'height', 'auto');
    } else {
      this.renderer.setStyle(searchFilterSheet, 'height', `calc(${searchFilterSheet.offsetHeight}px + 150px)`);

      this.renderer.setStyle(filterSheetFooter, 'left', '0');
      this.renderer.setStyle(filterSheetFooter, 'padding', '0');
      this.renderer.setStyle(filterSheetFooter, 'bottom', `${dialogRect.top}px`);
    }

  }

  handleScroll(target: HTMLElement): void {
    // const searchHead = target.querySelector('.search-head') as HTMLElement;
    // const searchAction = target.querySelector('.search-action') as HTMLElement;
    // const searchBody = target.querySelector('.search-body') as HTMLElement;
    // //const searchFilterSheet = target.querySelector('.filter-sheet-wrapper') as HTMLElement;

    // const dialogRect = target.getBoundingClientRect();
    // const topPosition = dialogRect.top;// + 10;

    // if (searchAction) {
    //   if (target.scrollTop > (searchHead?.offsetHeight ? searchHead.offsetHeight : 0)) {
    //     console.log('target.scrollTop', target.scrollTop, 'searchHead?.offsetHeight', searchHead?.offsetHeight)
    //     searchAction.classList.add('fixed');
  
    //     this.renderer.setStyle(searchAction, 'top', `${topPosition}px`);
    //     this.renderer.setStyle(searchAction, 'padding-bottom', `16px`);
    //     this.renderer.setStyle(searchBody, 'padding-top', `${searchAction.offsetHeight}px`);
    //   } else {
    //     searchAction.classList.remove('fixed');
  
    //     this.renderer.setStyle(searchBody, 'padding-top', `0px`);
    //   }

    // }

  }

  onCheckboxChange() {
    this.filterItems = this.filterItems.map((filterItem) => {
      if (filterItem.name === this.filterItemName) {
        this.filterItemDisplay.activeFilters = this.filterItemDisplay.data.reduce((count, item) => item.selected ? count + 1 : count, 0);

        return this.filterItemDisplay
      }

      return filterItem;
    });

    this.isSelected = this.checkIfAnyFilterItemSelected();
  }

  onCloseEvent() {
    this.searchGamesService.setSearchFilters(this.filterItems);
    this.closeEvent.next(null);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    const footer = document.querySelector('.filter-sheet-footer') as HTMLElement;
    const body = document.querySelector('.filter-sheet-body') as HTMLElement;
    const confirmBtn = document.querySelector('.confirm-btn') as HTMLElement;

    if (body && footer) {
      const bodyRect = body.getBoundingClientRect();
      const footerRect = footer.getBoundingClientRect();

      if (bodyRect.bottom <= window.innerHeight - 90) {
        footer.style.position = 'relative';
        footer.style.bottom = '0';
        footer.style.height = 'auto';
        footer.style.backdropFilter = 'none';
        confirmBtn.style.margin = '0'
      } else {
        footer.style.position = 'fixed';
        footer.style.bottom = '55px';
        footer.style.height = '120px';
        footer.style.backdropFilter = 'blur(3px)';
        confirmBtn.style.marginBottom = '0.5rem'
      }
    }
  }

  private checkIfAnyFilterItemSelected(): boolean {
    if (!this.filterItemDisplay || !this.filterItemDisplay.data) {
      return false;
    }

    return this.filterItemDisplay.data.some((item) => item.selected);
  }

}
