import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CHECK_SESSION } from '../../http/base-http.service';
import { BaseUsermgrService } from '../../http/base-usermgr.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginStatusService } from '../login/login-status.service';
import { LoggedStatus, UserInfo } from '../login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { LogExceptionService } from '../../utils/log-exception.service';

@Injectable({
  providedIn: 'root'
})
export class CheckSessionService extends BaseUsermgrService {


  constructor(http: HttpClient, cookie: CookieService,
    private loginStatusService: LoginStatusService,
    private LogExceptionService: LogExceptionService,
    @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  public requestCheckSession(): Observable<UserInfo> {
    return this.get<string>(CHECK_SESSION).pipe(
      switchMap((resp) => {
        this.loginStatusService.updateUserInfo({
          isLogged: resp === "OK" ? LoggedStatus.logged : LoggedStatus.notLogged,
        });
        return this.loginStatusService.getLoginStatus();
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.loginStatusService.updateUserInfo({
            isLogged: LoggedStatus.notLogged,
          });
        }
        // return an observable with a user-facing error message
        return this.loginStatusService.getLoginStatus();
      })
    );
  }
}
