<div class="new-password-body center" fxLayoutAdivgn="center center">
  <h1 class="page-header">{{page?.header}}
  </h1>
  <div [innerHTML]="body">
  </div>
</div>
<div class="desktop-panel">
  <div *ngIf="!isSuccess" class="casino-form-wrapper form-custom-design" fxLayout="row" fxLayoutAlign="center center">
    <div id="casino-form">
      <div *ngIf="hasError" class="">{{errorMsg}}</div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <form name="newPasswordForm" [formGroup]="newPasswordForm" (ngSubmit)="onSubmit(newPasswordForm)">
          <mat-form-field class="password" appearance="outline">
            <mat-label i18n>new password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="new_password">
            <img class="img-suffix" src="assets/playbison/login/{{hidePassword ? 'icon-eyedisabled' : 'icon-eye'}}.svg" matSuffix (click)="hidePassword = !hidePassword">
            <mat-error *ngIf="newPasswordForm.get('password')?.hasError('required')" i18n>Please enter your password.</mat-error>
            <mat-error *ngIf="newPasswordForm.get('password')?.hasError('minlength')" i18n>Your password is too short.</mat-error>
            <mat-error *ngIf="newPasswordForm.get('password')?.hasError('pattern')">
              {{newPasswordForm.get('password').errors.pattern.msg}}
            </mat-error>
            <mat-error *ngIf="newPasswordForm.get('password')?.errors?.serverError">
              {{ newPasswordForm.get('password').errors?.serverError }}
            </mat-error>
          </mat-form-field>
          <div class="buttons">
            <div><button round-button color="warn" filled="filled" size="large" type="submit" [disabled]="!newPasswordForm.valid" i18n>submit</button></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>