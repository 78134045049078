import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { BackofficeBonuses, BonusInfo, BonusListResponse } from './bonus.models';
import { filter, map, switchMap, take, tap, toArray } from 'rxjs/operators';

import { BaseUsermgrService } from '../../http/base-usermgr.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, defaultIfEmpty, from, Observable, of } from 'rxjs';
import { BO_BONUS_INFO } from '../../http/base-http.service';
import { HOST_NAME } from 'src/app/app-routing.module';
import { ClonerService } from '../../utils/clone-object.service';
import { UserCampaignService } from "../../campaigns/campaign.service";

const query = gql`query BonusList($locale: Locale!){
  bonusList(locales: [$locale, en],where: {name: "deposit"}) {
    bonuses {
      name
      displayName
      teaserText
      externalId
      isFreeSpin
      highlightBonus
      pagesNew{
        name
      }
      thumbnail {
        url
      }
      isPrizeEngineEligiblePlayers
    }
  }
  }
  `
@Injectable({
  providedIn: 'root'
})
export class BonusListService extends BaseUsermgrService {

  constructor(private apollo: Apollo, private cloneService: ClonerService, http: HttpClient, cookie: CookieService, private campaignService: UserCampaignService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) { super(http, cookie, locale, hostName) }

  private store = new BehaviorSubject<BonusInfo[]>([]);

  updateStore() {
    console.log("calling bonus list")
    let cmsList$ = this.apollo
      .watchQuery<BonusListResponse>({
        query: query,
        variables: {
          locale: this.locale
        }
      })
      .valueChanges;
    let boList$ = this.get<BackofficeBonuses>(BO_BONUS_INFO);

    boList$.pipe(
      switchMap((boResp) => {
        return cmsList$.pipe(
          take(1),
          switchMap(cmsResp => from(cmsResp.data.bonusList.bonuses)),
          filter(item => {
            if (!item.isFreeSpin) {
              return boResp.bonuses.filter(b => b.id.toString() === item.externalId).length > 0 || item.externalId === '0' || item.externalId === "-2"
            }
            else {
              return boResp.freespins.filter(b => b.code === item.externalId).length > 0
            }
          }),
          map(item => {
            let mutableItem = this.cloneService.deepClone<BonusInfo>(item);
            const boMethod = boResp.bonuses.filter(b => b.id.toString() === item.externalId)[0];

            mutableItem.excludedOperators = boMethod?.excluded_operators;
            mutableItem.includedOperators = boMethod?.included_operators;

            return mutableItem;
          })
        )
      }
      ), toArray(),
      switchMap(bonuses => {
        if (bonuses.filter(bonus => bonus.isPrizeEngineEligiblePlayers).length === 0) {
          return of(bonuses);
        }

        return this.campaignService.isUserEligibleCampaignBonuses(bonuses.map(bonus => bonus.externalId)).pipe(
          map((eligibilityList) => {
            const eligibilityMap = new Map(eligibilityList);
            return bonuses.filter((bonus) => (
              eligibilityMap.get(bonus.externalId) && bonus.isPrizeEngineEligiblePlayers) || !bonus.isPrizeEngineEligiblePlayers);
          })
        );
      }),
    ).subscribe(resp => {
      const sorted = resp.sort((a, b) => (a.externalId > b.externalId) ? 1 : -1);
      this.store.next(sorted)
    })

  }


  public fetchData(): Observable<BonusInfo[]> {
    if (this.store.value.length === 0) this.updateStore();
    return this.store.asObservable();
  }

  public getFromStore(): Observable<BonusInfo[]> {
    return this.store.asObservable().pipe(defaultIfEmpty([]));
  }
  // .filter(([product, category]) => {
  //   return product.cat_id.some(id => id === category.id);
  // })
  // .map(([product, category]) => product);

  // }
}
