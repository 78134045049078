<div class="auth-content" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}">
    <mat-divider></mat-divider>

    <div class="auth-body">
        <div class="image-box">
            <img src="{{licenceService.getKey('login-wrongpassword')}}">
        </div>

        <div class="text-box">
            <h2 i18n>Don't worry :)</h2>
            <p i18n>Resetting your password only takes a few seconds.</p>
        </div>
    </div>

    <div class="auth-footer">
        <button round-button size="large" filled="filled" color="accent" type='button' style="text-transform: uppercase;" (click)="lostPassword()" i18n>new password</button>
        <button round-button size="large" color="accent" type='button' style="text-transform: uppercase;" class="wrongpassword-outline-btn" (click)="contactSupport()" i18n>contact support</button>
    </div>
</div>