import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, map, take, takeUntil, timer } from 'rxjs';
import { BasePageComponentWithDialogs } from 'src/app/components/base-page/base-page.component';
import { LoginSmsVerificationService } from 'src/app/services/auth/login/login-sms-verification.service';
import { RegistrationService } from 'src/app/services/signup/registration-fields.service';
import { RegistrationFields } from 'src/app/services/signup/registration.models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { CountryCode, CountryCodes } from 'src/app/utils/country-codes';
import { isPhoneNumberValid, isSuffixValid, patchTelephone } from 'src/app/utils/valid-tlephone';

@Component({
  selector: 'app-signup-verify-sms',
  templateUrl: './signup-verify-sms.component.html',
  styleUrls: ['./signup-verify-sms.component.scss']
})
export class SignupVerifySmsComponent extends BasePageComponentWithDialogs implements AfterViewInit {

  constructor(
    protected formBuilder: UntypedFormBuilder,
    protected registrationService: RegistrationService,
    dialog: MatDialog,
    public detectDesktopService: DetectDeviceService,
    protected loginWithSms: LoginSmsVerificationService,
    protected renderer: Renderer2,
    protected licenceService: LicenceService

  ) { super(dialog, detectDesktopService) }


  @ViewChild('smsinput') input: ElementRef;
  codeArray = [null, null, null, null];
  smsCode: string;
  isCodeValid: boolean = true;
  isChangePhone: boolean = false;

  prefixCountry: string;
  isTelephoneValid: boolean = true;
  telephoneSuffix: string;
  countryCodes: Array<CountryCode> = CountryCodes;
  isPromocdeFlow = false;
  isSmsVerification = false;
  isReedeemCompleted = false;

  smsForm = this.formBuilder.group({
    code: ['', [Validators.required, Validators.minLength(5)]],
  });

  changePhoneForm = this.formBuilder.group({
    telephone: ['', [Validators.required]],
  });

  @Output() isCompleted = new EventEmitter<boolean>();
  private _registrationFields: RegistrationFields;

  @Input()
  set registrationFields(name: RegistrationFields) {
    this._registrationFields = name;
  }

  get registrationFields(): RegistrationFields { return this._registrationFields; }


  formSubmited: boolean = false;
  telephone: string;
  timerValue: number = 0;
  pageView: string;
  actionTimer$: Subscription;


  ngOnInit(): void {
    this.registrationService.getSmsVerificationAddTelephone().pipe(take(1)).subscribe(resp => {
      this.telephone = resp?.user?.telephone;
      if (this.telephone) {
        const longest_prefix = this.telephone.slice(0, 4);
        this.determinePrefix(longest_prefix, this.countryCodes);
        this.triggerTelephoneChange()
      }
    })
    this.startTimer();
    this.isDesktop = this.detectDesktopService.isDesktop();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.input.nativeElement.focus(), 50);
  }

  clearInput() {
    this.smsCode = '';
    this.input?.nativeElement?.focus();
    this.smsForm.get('code').setValue(this.smsCode);
  }

  startTimer() {
    const start = 60;

    this.actionTimer$ = timer(0, 1000).pipe(
      takeUntil(this.unsubscribe),
      map(i => start - i),
      take(start + 1),
    ).subscribe((value) => {
      this.timerValue = value;
    })
  }

  resendCode() {
    if (this.formSubmited || this.timerValue > 0) return;
    this.formSubmited = true;

    this.clearInput();

    this.registrationService.postSmsAdjustTelephone(this.isPromocdeFlow ? {} : { telephone: this.telephone }, this.isPromocdeFlow).pipe(take(1)).subscribe(
      {
        next: resp => {
          this.formSubmited = false;
          // this.openSuccessDialog();
          this.pageView = 'OPENMODAL';
        },
        error: error => {
          this.formSubmited = false;
          this.openErrorDialog(error.error.errors[0].telephone || null);
        }
      })
  }

  private verifyInputsAndSend() {
    if (this.smsCode.length === 5) {
      return true
    } else {
      this.renderer.addClass(this.input, 'error');
      return false;
    }
  }

  onSubmit(smsForm, successCallback: (resp: any) => void = null) {
    console.log(this.smsForm.get('code').value);
    this.smsCode = this.smsForm.get('code').value;

    this.isCodeValid = this.verifyInputsAndSend();
    if (this.formSubmited) return;
    this.formSubmited = true;

    if (this.isPromocdeFlow) {
      this.registrationService.postSmsVerificationCodePromocdeFlow(smsForm).pipe(take(1)).subscribe({
        next: (resp) => {
          this.isReedeemCompleted = true;
          this.isSmsVerification = false;
          if (!!successCallback) {
            successCallback(resp);
          }
        },
        error: (error) => {
          this.formSubmited = false;

          if (error.status === 400) {
            if (error?.error?.errors?.code) this.openErrorDialog(error?.error?.errors?.code);
          }
          else {
            !!error.error ? this.openErrorDialog(error.error.error) : this.openErrorDialog();
          }
        }
      })
    }
    else {
      this.registrationService.postSmsVerificationCode(smsForm).pipe(take(1)).subscribe({
        next: (resp) => {
          this.loginWithSms.loginWithToken(resp.firebase_token).pipe(take(1)).subscribe(() =>
            this.isCompleted.next(true)
          )
        },
        error: (error) => {
          this.formSubmited = false;

          if (error.status === 400) {
            if (error?.error?.errors?.code) this.openErrorDialog(error?.error?.errors?.code);
          }
          else {
            !!error.error ? this.openErrorDialog(error.error.error) : this.openErrorDialog();
          }
        }
      })
    }
  }

  public addPhoneVerification(changePhoneForm: UntypedFormGroup) {
    if (this.formSubmited || !changePhoneForm.valid) return;
    this.formSubmited = true;
    this.clearInput();
    this.registrationService.postSmsVerificationAddTelephone(this.changePhoneForm, this.isPromocdeFlow).pipe(take(1)).subscribe({
      next: (resp) => {
        this.formSubmited = false;
        this.isChangePhone = false;
        this.telephone = changePhoneForm.value.telephone;
        this.pageView = 'OPENCHANGENUMBERMODAL';
      },
      error: (error) => {
        this.formSubmited = false;
        this.openErrorDialog(error.error.errors[0]?.telephone || error.error.errors['telephone'][0]);
      }
    })
  }

  triggerTelephoneChange() {
    // const telephone = this.prefixCountry + this.telephoneSuffix;
    // this.isTelephoneValid = isPhoneNumberValid(telephone);
    // this.changePhoneForm.controls['telephone'].setValue(telephone);

    this.changePhoneForm.controls['telephone'].setValue('');
    this.telephoneSuffix = patchTelephone(this.telephoneSuffix, this.prefixCountry);
    const telephone = this.prefixCountry + this.telephoneSuffix;
    if (!!this.telephoneSuffix) {
      this.isTelephoneValid = (isPhoneNumberValid(telephone) && isSuffixValid(this.telephoneSuffix, this.prefixCountry)) ? true : false;
      if (this.isTelephoneValid) {
        this.changePhoneForm.controls['telephone'].setValue(telephone);
      }
    }

  }

  determinePrefix(prefix, codes) {
    const temp = codes.filter(elem => elem.dial_code === prefix);
    if (temp.length != 1) {
      if (prefix.length > 2) this.determinePrefix(prefix.slice(0, prefix.length - 1), codes)
    }
    else {
      this.prefixCountry = prefix;
      this.telephoneSuffix = this.telephone.slice(prefix.length);
    }
  }

}
