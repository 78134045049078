import { Component, Input, OnInit } from '@angular/core';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

@Component({
  selector: 'app-signup-steps',
  templateUrl: './signup-steps.component.html',
  styleUrls: ['./signup-steps.component.scss']
})
export class SignupStepsComponent implements OnInit {
  isDesktop: boolean = false;
  constructor(
    private detectDeviceService: DetectDeviceService,
  ) { }

  @Input() step:string;

  ngOnInit(): void {
    this.isDesktop = this.detectDeviceService.isDesktop();
  }

}
