import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { BaseUsermgrService } from '../http/base-usermgr.service';
import {
  AdjustTelehoneRequest,
  RegistrationFields,
  RegistrationStepResponse,
  SmsVerificationResponse,
  SmsVerificationResponsePromocdeFlow,
  TelephoneInfoReponse
} from './registration.models';
import { REGISTRATION, SMS_VERIFICATION_ADD_TELEPHONE, SMS_VERIFICATION_ADD_TELEPHONE_PROMOCODE, SMS_VERIFICATION_RESEND_PROMOCODE, SMS_VERIFICATION_VERIFY, SMS_VERIFICATION_VERIFY_PROMOCODE } from '../http/base-http.service';
import { UntypedFormGroup } from '@angular/forms';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends BaseUsermgrService {

  constructor(http: HttpClient, cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  public requestRegistrationFields(): Observable<RegistrationFields> {
    return this.options<RegistrationFields>(REGISTRATION)
  }

  public postRegistrationStep(registrationStepForm: UntypedFormGroup): Observable<RegistrationStepResponse> {
    return super.postRequest<RegistrationStepResponse>(REGISTRATION, registrationStepForm);
  }

  public postSmsVerificationAddTelephone(smsVerificationForm: UntypedFormGroup, isPromocdeFlow = false): Observable<SmsVerificationResponse> {
    if (isPromocdeFlow) {
      return super.postRequest<SmsVerificationResponse>(SMS_VERIFICATION_ADD_TELEPHONE_PROMOCODE, smsVerificationForm);

    } else {
      return super.postRequest<SmsVerificationResponse>(SMS_VERIFICATION_ADD_TELEPHONE, smsVerificationForm);
    }
  }

  public postSmsAdjustTelephone(smsVerificationForm: AdjustTelehoneRequest, isPromocdeFlow = false): Observable<SmsVerificationResponse> {
    if (isPromocdeFlow) {
      return super.postRequestJson<SmsVerificationResponse>(SMS_VERIFICATION_RESEND_PROMOCODE, smsVerificationForm);

    } else {
      return super.postRequestJson<SmsVerificationResponse>(SMS_VERIFICATION_ADD_TELEPHONE, smsVerificationForm);
    }
  }

  public getSmsVerificationAddTelephone(): Observable<TelephoneInfoReponse> {
    return super.get<TelephoneInfoReponse>(SMS_VERIFICATION_ADD_TELEPHONE);
  }

  public postSmsVerificationCode(smsVerificationCodeForm: UntypedFormGroup): Observable<SmsVerificationResponse> {
    return super.postRequest<SmsVerificationResponse>(SMS_VERIFICATION_VERIFY, smsVerificationCodeForm);
  }
  public postSmsVerificationCodePromocdeFlow(smsVerificationCodeForm: UntypedFormGroup): Observable<SmsVerificationResponsePromocdeFlow> {
    return super.postRequest<SmsVerificationResponsePromocdeFlow>(SMS_VERIFICATION_VERIFY_PROMOCODE, smsVerificationCodeForm);
  }
}
