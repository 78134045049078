<div class="categories-head">
    <div class="categories-title">
      <img [src]="iconUrl" [alt]="{displayName}" />
      <h3>{{displayName}}</h3>
    </div>
    <!-- <div class="categories-nav">
      <a i18n [routerLink]="link">See All</a>
      <button (click)="swipeToFront()"><img src="{{licenceService.getKey('icon-right')}}" icon-btn size="medium" direction="left"></button>
      <button (click)="swipeToBack()"><img src="{{licenceService.getKey('icon-right')}}" icon-btn size="medium"></button>
    </div> -->
    <div class="categories-nav">
      <a i18n [routerLink]="link">See All</a>
      <img src="{{licenceService.getKey('icon-right')}}" alt="arrow" icon-btn size="medium" direction="left" (click)="swipeToFront()">
      <img src="{{licenceService.getKey('icon-right')}}" alt="arrow" icon-btn size="medium" class="button-next" (click)="swipeToBack()">
  </div>
</div>