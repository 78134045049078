<div fxLayout="row" fxLayoutAlign="center stretch" class="account-balance-container" *ngIf="balanceInfo">
    <div class="real-balance" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="balance-icon"><img [ngClass]="{'disable-img': realBalanceValue}" src="assets/playbison/icon-wallet-sticky.png" alt="icon-wallet-sticky"></div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="info-text" [ngClass]="{'empty-balance': realBalanceValue}" i18n>Real funds</div>
            <div class="info-balance" [ngClass]="{'empty-balance': realBalanceValue}" >{{balanceInfo.balance}} {{balanceInfo.currency}}</div>
        </div>
    </div>
    <div class="bonus-balance" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="balance-icon"><img [ngClass]="{'disable-img': bonusBalanceValue}" src="assets/playbison/icon-my-bonuses.png" alt="icon-bonus-box"></div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="info-text" [ngClass]="{'empty-balance': bonusBalanceValue}" i18n>Bonus funds</div>
            <div class="info-balance" [ngClass]="{'empty-balance': bonusBalanceValue}" >{{balanceInfo.bonus_balance}} {{balanceInfo.currency}}</div>
        </div>
    </div>
</div>