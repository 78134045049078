<div *ngIf="enableTournamentsDisplay" class="tournament-wrapper">
    <ng-container *ngIf="isComponentsReady else skeleton">
        <swiper fxFlex="auto" class="operator-swiper" [config]="config">
            <ng-template swiperSlide *ngFor="let item of missions; let index=index; trackBy: trackByBannerItems">
                <app-mission-info-box [mission]="item" [tournamentIndex]="index" [isLogged]="isLogged"></app-mission-info-box>
            </ng-template>
        </swiper>
    </ng-container>
</div>

<ng-template #skeleton>
    <div class="skeleton">
        <img src="{{licenceService.getKey('icon-skeleton')}}" />
    </div>
</ng-template>