import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum AdformTrackingPage {
  HOMEPAGE,
  FIRST_DEPOSIT,
  DEPOSIT,
  ALL_PAGES,
  REGISTRATION_START,
  REGISTRATION_END
}

export const ADFORM_TRACKING_URL: Map<AdformTrackingPage, string> = new Map<AdformTrackingPage, string>(
  [
    [AdformTrackingPage.REGISTRATION_START, 'https://track.adform.net/Serving/TrackPoint/?pm=3213262&ADFPageName=RegisterStart&ADFdivider=|'],
    [AdformTrackingPage.REGISTRATION_END, 'https://track.adform.net/Serving/TrackPoint/?pm=3213262&ADFPageName=RegisterEnd&ADFdivider=|'],
    [AdformTrackingPage.FIRST_DEPOSIT, 'https://track.adform.net/Serving/TrackPoint/?pm=3213262&ADFPageName=FirstDeposit&ADFdivider=|'],
    [AdformTrackingPage.DEPOSIT, 'https://track.adform.net/Serving/TrackPoint/?pm=3213262&ADFPageName=DepositSuccess&ADFdivider=|'],
    [AdformTrackingPage.HOMEPAGE, 'https://track.adform.net/Serving/TrackPoint/?pm=3213262&ADFPageName=Homepage&ADFdivider=|'],
    [AdformTrackingPage.ALL_PAGES, 'https://track.adform.net/Serving/TrackPoint/?pm=3213262&ADFPageName=AllPages&ADFdivider=|']
  ]
)

@Injectable({
  providedIn: 'root'
})
export class AdformTrackingService {

  private trackingPageUrl = new BehaviorSubject<string>(null);

  constructor() { }

  setTrackingPage(value: AdformTrackingPage) {
    this.trackingPageUrl.next(ADFORM_TRACKING_URL.get(value));
  }

  subscribeTrakcingPage(): Observable<string> {
    return this.trackingPageUrl.asObservable();
  }
}
