import { Component, ElementRef, ViewChild } from '@angular/core';
import { ChargebackService } from '../../../../services/profile/chargeback.service';
import { TranslationConfig } from '../../../../utils/translate-config';
import { ToggleMenuBackService } from '../../../../services/utils/toggle-menu-back.service';
import { BestGamesService } from '../../../../services/client-area/best-games.service';
import { DetectDeviceService } from '../../../../services/utils/detect-device.service';
import { LoginStatusService } from '../../../../services/auth/login/login-status.service';
import { AvatarService } from '../../../../services/profile/avatar.service';
import { GamificationLevelService } from '../../../../services/profile/gamification-level.service';
import { PrizeBoxService } from '../../../../services/client-area/prize-box.service';
import { BonusLotteryService } from '../../../../services/profile/bonuslottery.service';
import { Router } from '@angular/router';
import { BaseMainClientAreaComponent } from '../base/base-client-area.component';
import { LicenceService } from 'src/app/services/utils/licence.service';
import * as PixiSpine from 'pixi-spine';
import * as PIXI from 'pixi.js';

const ANIMATION_CANVAS_SIZE_HEIGHT = 90;
const ANIMATION_CANVAS_SIZE_WIDTH = 90;
const ANIMATION_SIZE_WIDTH = 120;
const ANIMATION_NAME = 'animation';

const PATH_TO_ANIMATION_JSON = 'assets/playbison/jackpot/jackpot_120x120.json';

@Component({
  selector: 'app-mobile-main-client-area',
  templateUrl: './mobile-main-client-area.component.html',
  styleUrls: ['./mobile-main-client-area.component.scss']
})
export class MobileMainClientAreaComponent extends BaseMainClientAreaComponent {

  app: PIXI.Application;
  canvasHeight: number;
  cashBackTwoActive:boolean = false;

  @ViewChild('pixiAnimation') pixiAnimation: ElementRef;

  private animationSize = {
    width: ANIMATION_CANVAS_SIZE_WIDTH,
    height: ANIMATION_CANVAS_SIZE_HEIGHT,
    scale: ANIMATION_CANVAS_SIZE_WIDTH / ANIMATION_SIZE_WIDTH,
  };

  constructor(
    protected licenceService: LicenceService,
    chargebackService: ChargebackService,
    translationConfig: TranslationConfig,
    toggleBackService: ToggleMenuBackService,
    bestGamesService: BestGamesService,
    deviceDetectService: DetectDeviceService,
    loginStatusService: LoginStatusService,
    avatarService: AvatarService,
    gamificationLevelService: GamificationLevelService,
    prizeBoxService: PrizeBoxService,
    bonusLotteryService: BonusLotteryService,
    router: Router) {
    super(chargebackService, translationConfig, toggleBackService, bestGamesService, deviceDetectService, loginStatusService, avatarService, gamificationLevelService, prizeBoxService, bonusLotteryService, router);
  }

  ngOnInit(): void {
    this.canvasHeight = this.animationSize.height;
    this.initialize();
  }

  ngAfterViewInit() {
    this.app = new PIXI.Application({
      preserveDrawingBuffer: true,
      backgroundAlpha: 0,
      width: this.animationSize.width,
      height: this.animationSize.height,
    });

    const checkInterval = setInterval(() => {
      if (this.pixiAnimation && this.pixiAnimation.nativeElement) {
        clearInterval(checkInterval);
        PIXI.Assets.load(PATH_TO_ANIMATION_JSON).then(this.onAssetsLoaded);
      }
    }, 100);
  }

  onAssetsLoaded = (prizeboxAsset) => {
    const prizeBoxSpine = new PixiSpine.Spine(prizeboxAsset.spineData);

    prizeBoxSpine.state.data.skeletonData.bones;
    prizeBoxSpine.scale.x = this.animationSize.scale;
    prizeBoxSpine.scale.y = this.animationSize.scale;
    prizeBoxSpine.x = this.animationSize.width / 2;
    prizeBoxSpine.y = this.animationSize.height / 2;

    prizeBoxSpine.state.setAnimation(0, ANIMATION_NAME, true);

    if (this.app && this.app.stage) {
      this.app.stage.addChild(prizeBoxSpine);
    }

    if (this.app && this.app.stage) {
      this.app.stage.interactive = true;
    }

    if (this.pixiAnimation && this.pixiAnimation.nativeElement && this.app && this.app.view) {
      this.pixiAnimation.nativeElement.appendChild(this.app.view);
    }
  };
}
