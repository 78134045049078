<div class="swiper-slide" (click)="onGameClick()">
  <div class="play-effect">
    <img src="assets/desktop/play-button-overlay.svg" alt="play-button-overlay">
  </div>
  <div class="thumbnail">
    <img alt="{{game.name}}" data-src="{{ game.thumbnail?.url || game?.thumbnailDesktopSquare?.url }}" />
    <div *ngIf="game.tag?.includes('exclusive')" class="exclusive">
      <img src="assets/icons/tag-lemon-exclusive-mobile.svg">
    </div>
  </div>
  <p style="padding-top: 0.25rem;">{{game.name}}</p>
</div>
