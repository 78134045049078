<ng-container *ngIf="resp">
    <div class="label-container" (click)="openLotteryWheel()" [ngClass]="isDesktop ? 'desktop': 'mobile'" *ngIf="!isClaimed else showboxes" fxLayout="row" fxLayoutGap="9px" fxLayoutAlign="space-evenly center">
        <div fxLayoutAlign="center center" class="rectangle">
            <img src="{{licenceService.getKey('icon-bonus-wheel')}}" />
        </div>
        <p fxFlex="70" i18n>Get your bonus spin now!</p>
        <button *ngIf="isDesktop" fxFlex="20" round-button color="warn" filled="empty" i18n>Click to Spin!</button>
        <div *ngIf="!isDesktop">
            <img src="{{licenceService.getKey('icon-right')}}" icon-btn size="large" alt="icon-arrow-right">
        </div>
    </div>
</ng-container>
<ng-template #showboxes>
    <app-lottery-prize-box [prizeBoxLottery]="resp"></app-lottery-prize-box>
</ng-template>