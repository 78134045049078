import { environment as common } from './environment.playbison.firebase';


export const environment = {
  ...common,
  production: false,
  enableServiceWorker: false,
  // apiUrl: 'https://api-test.playbison.com',
  // walletUrl: 'https://api-test.playbison.com',
  // paymentUrl: 'https://api-test.playbison.com',
  apiUrl: 'http://localhost:4200',
  walletUrl: 'http://localhost:4200',
  paymentUrl: 'http://localhost:4200',
  uiBackend: 'https://prizeengine.playbison.com',
  // uiBackend: 'http://localhost:8000',
  fallbackLanguage: "de-AT",
  isDeploymentConfig: true,
  affiliateProgram: 'https://bison.partners',
  cmsDraftToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MTY0NTA3ODUsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtbGVtb24tY2FzaW5vLmh5Z3JhcGguY29tL3YyL2Nsd2cxcXRjajAwMDAwMXc2MjdmMWVjZGwvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtZXUtY2VudHJhbC0xLWxlbW9uLWNhc2luby5oeWdyYXBoLmNvbS8iLCJzdWIiOiIxYWVkMTczZi1mYTMyLTQxNTEtODZkZi1lNGY0YTAzZjQzZjUiLCJqdGkiOiJjbHdpeWhhMmswMG15MDh1dTk0MWY0OHk0In0.EOELByDgZpz96YsWPXEINd24FXw2imDCfncY15abELzA_o5SfRrbHMwRfLOPdWFlyPqZHY70XXuNPqRleAlYuyp5X_y5AinZssWCnkaJ_jgFTq8Vor5GUHi1DTGKC1LxZNkpzV6Q1iFb6kmSXqcuHodP3iAqwtqgPOkPc8BjrLZ7UGOK9Eis7A_68W_xfNwVPsunPlojtHCzgcxN4PT367nSiiLmNjUWZcXeHIMQ_mHrCLSRGAKqQw3d7ZmLuuspkdzAtk77HnDJsCj1FeiPDEfC_JBMIfsr5mnSplbENcKLqqjITnnvH3-2i-nevwxTdyJBQk3ZqmrcuCtCOuC6IGC6CN02dsnMAzsufC_mjqgIjI7E7y0illyQtQkTi5Ook4GZLrZ2NFQBVH1LX8FDEGbp96xGW0PwWv2vHZCT_rWSxowR1NwSvEEFIjA3937aEQ1qXwb-fFxkHOwG6lLeMEGsbzhYCzFdeuOhT-rUkPZqN_RdvkE9DWWQvvIAg4okHXW2L6aRiiBxffKNhdCpJz7ZPHirp_DqbHWNqrYKazfLakkcIC2CLZ8_00UDneOWu38WcX_M3Q4IiDRDGY4AlDYChBvIZxzCgIYJ4wnAnN1aDWMTOvvRvtfnFk1Y43rrbA6wMM-Z3lHqpfACKy9wfZ7mEX25O4Un7R5h2QFBT6w",
  baseCurrency: "EUR",
  enableTournamentsDisplay: true,
  enablePaymentIQ: true,
  chatToken: "b7240bde-642b-43b2-b46e-ab5af022f1cd",
  cmsApi: 'https://eu-central-1-lemon-casino.cdn.hygraph.com/content/clwg1qtcj000001w627f1ecdl/master',
  firebaseConfig: {
    apiKey: "AIzaSyDtD8Zb6x1zTbLi8PBH3SjDq6ORtkoafUE",
    authDomain: "for-fun-338904.firebaseapp.com",
    projectId: "for-fun-338904",
    storageBucket: "for-fun-338904.appspot.com",
    messagingSenderId: "736138600386",
    appId: "1:736138600386:web:dbef0b35eabc21cc20c05b",
    measurementId: "G-XG9ZNQ4BNY"
  },
  paymentIqConfig: {
    url: 'https://test-api.paymentiq.io/paymentiq/api',
    mid: 100624998,
    sessionIdName: 'forfun_ses',
    userPrefix: 'user'
  },
  checkSessionInterval: 5000,
  checkPopularGamesUsersInterval: 30000,
  checkInboxInterval: 30000,
  minBalanceThresholds: {
    USD: 10,
    EUR: 10,
    GBP: 10,
    PLN: 20,
    HUF: 1500,
    INR: 500,
    CAD: 30,
    AUD: 32,
    NZD: 35,
    ZAR: 150,
    NOK: 100
  },
  defaultDepositValues: {
    PLN: [50, 100, 200, 500, 1000],
    USD: [20, 50, 100, 200, 500],
    EUR: [20, 50, 100, 200, 500],
    GBP: [20, 50, 100, 200, 500],
    HUF: [5000, 10000, 20000, 50000, 100000],
    INR: [500, 1000, 2000, 5000, 10000],
    CAD: [20, 50, 100, 200, 500],
    AUD: [20, 50, 100, 200, 500],
    NZD: [20, 50, 100, 200, 500],
    ZAR: [150, 300, 500, 1000, 2000, 5000],
    NOK: [200, 500, 1000, 2000, 5000]
  }
};
