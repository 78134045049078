<!-- <div class="dialog-wrapper" *ngIf="successData.msg else remindpassword">
    <div class="dialog-container" fxLayoutAlign="space-between center" fxLayout="column">
        <h2 *ngIf="successData.title">{{successData.title}}</h2>
        <div>
            <p>
                {{successData.msg}}
            </p>
        </div>
        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
            <div class="buttons">
                <button round-button *ngIf="successData.additionalButtonLink" [routerLink] ="successData.additionalButtonLink" (click)="closeDialog()" style="margin-bottom: 7px;" type='button' filled="filled" i18n>Add Documents</button>
                <button mat-flat-button color="primary" (click)="closeDialog()" type='button'>OK</button>
            </div>
        </div>
    </div>
    <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
</div>


<ng-template #remindpassword>
    <div class="dialog-wrapper">
        <div class="dialog-container" fxLayoutAlign="space-between center" fxLayout="column">
            <div>
                <p i18n>Your request was correctly updated.</p>
            </div>
            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                <div class="buttons">
                    <button mat-flat-button color="primary" (click)="closeDialog()" type='button'>OK</button>
                </div>
            </div>
        </div>
        <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
    </div>
</ng-template> -->

<div class="dialog-wrapper">
    <div class="dialog-header">
        <ng-container *ngIf="successData.title">
            <h2>{{successData.title}}</h2>
        </ng-container>

        <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
    </div>

    <mat-divider></mat-divider>

    <div class="dialog-body">
        <div class="image-box">
            <img src="{{licenceService.getKey('success-tick')}}">
        </div>
        <ng-container *ngIf="!successData.msg else successDataMsg">
            <p i18n>Your request was correctly updated.</p>
        </ng-container>

        <ng-template #successDataMsg>
            <p i18n>{{successData.msg}}</p>
        </ng-template>
    </div>

    <div class="dialog-footer">
        <div class="buttons">
            <button round-button color="warn" (click)="closeDialog()" type='button'>OK</button>
        </div>
    </div>
</div>