<ng-container *ngIf="games">
        <div class="categoryNameContainer" fxLayout="row" fxLayoutAlign="center center">
                <div fxLayoutAlign="center center" class="category-title">
                        <img class="category-icon" src="{{licenceService.getKey('icon-player-choice')}}" alt="icon-my-games">
                        <h2>{{categoryName}}</h2>
                </div>
                <span class="spacer"></span>
                <a class="button-all-games" fxLayoutAlign="end center" fxLayout="row" routerLink="{{linkUrl}}">
                        <span i18n>All</span>
                        <!-- <div> -->
                                <img src="{{licenceService.getKey('icon-right')}}" icon-btn size="large">
                        <!-- </div> -->
                </a>
        </div>
        <ng-container *ngIf="!isDesktop">
                <app-front-page-games *ngIf="games" [isLoading]="isLoading" [data]="gameCategory" [rowsCount]="gameCategory.numberGameRows ?? 1"></app-front-page-games>
        </ng-container>
        <ng-container *ngIf="isDesktop">
                <app-desktop-front-page-game [size]="'square'" [iteration]="6" [data]="games">
                </app-desktop-front-page-game>
        </ng-container>
</ng-container>
