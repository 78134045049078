<app-desktop-header *ngIf="detectDeviceService.isDesktop()"></app-desktop-header>
<!-- <app-desktop-category-menu [data]="categories" *ngIf="detectDeviceService.isDesktop()"></app-desktop-category-menu> -->
<div class="page-body">
    <div class="content">
        <div class="category-name-container" fxLayout="row" fxLayoutAlign="start center">
            <!-- <h1 class="category-title">{{categoryName}}</h1> -->
            <h1 fxLayoutAlign="center center" class="category-title">
                <img class="category-icon" src="{{licenceService.getKey('icon-player-choice')}}">
                <span i18n>last played</span>
            </h1>
        </div>
        <!-- <mat-grid-list cols="3" gutterSize="3px" rowHeight="139px" appResponsiveColumns>
            <mat-grid-tile class="game-tile" *ngFor="let item of data?.game">
                <app-game-card (clickEvent)="openDialog($event)" [data]="item"></app-game-card>
            </mat-grid-tile>
        </mat-grid-list> -->
        <ng-container *ngIf="games">
            <div class="game-card-wrapper">
                <div class="game-card" *ngFor="let item of games">
                    <app-game-card [data]="item"></app-game-card>
                    <!-- <div class="spacer"></div> -->
                </div>
            </div>
        </ng-container>
        <router-outlet></router-outlet>
    </div>
</div>