import { Inject, Injectable } from "@angular/core";
import { BaseFirebaseService } from "../http/base-firebase.service";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { HOST_NAME } from "src/app/app-routing.module";
import { of, switchMap, take } from "rxjs";
import { LOG_EXCEPTION } from "../http/base-http.service";

@Injectable({
    providedIn: 'root'
})
export class LogExceptionService {
    constructor(
        private http: HttpClient,
        @Inject(HOST_NAME) public hostName
    ) {

    }

    public logException(error: any, msg: string, params: { [key: string]: string }) {
        return this.http.post<{ exceptionStr: string, params: { [key: string]: string } }>(LOG_EXCEPTION, { exceptionStr: msg, params: params }).pipe(
            take(1),
            switchMap(() => {
                return of(error)
            })
        )
    }

}