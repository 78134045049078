<div *ngIf="!detectDeviceService.isDesktop() else desktop" class="login-button" fxLayout="row" fxLayoutAlign="center center" [routerLink]="paymentUrl">
  <div class="balance-container" fxLayout="row" fxLayoutGap="9px" fxLayoutAlign="space-between center" [ngStyle]="{'padding':balanceInfo?.bonus_balance > 0 ? '4px 4px 4px 12px':'4px 4px 4px 20px'}">
    <div class="mobile-balance" [ngClass]="{'text-center':textCenterStyle}"><strong>{{balanceInfo?.balance}}</strong><ng-container *ngIf="balanceInfo?.bonus_balance > 0"> <br><img style="margin-right:4px;" src="{{licenceService.getKey('icon-wallet-bonus-black')}}" alt="icon-wallet-bonus">{{balanceInfo?.bonus_balance}}</ng-container> {{balanceInfo?.currency}}</div>
    <div class="add-icon mobile" fxLayoutAlign="center center" fxLayout="row">
      <img src="{{licenceService.getKey('icon-add')}}" />
    </div>
  </div>
</div>

<ng-template #desktop>
  <div class="login-button desktop" fxLayout="row" fxLayoutAlign="center center" [routerLink]="paymentUrl">
    <div class="balance-container" fxLayout="row" fxLayoutGap="9px" fxLayoutAlign="space-between center" [ngStyle]="{'padding':balanceInfo?.bonus_balance > 0 ? '4px 4px 4px 12px':'4px 4px 4px 20px'}">
      <div class="desktop-balance"><strong>{{balanceInfo?.balance}}</strong><ng-container *ngIf="balanceInfo?.bonus_balance > 0"> <br><img style="margin-right:4px;" src="{{licenceService.getKey('icon-wallet-bonus-black')}}" alt="icon-wallet-bonus">{{balanceInfo?.bonus_balance}}</ng-container> {{balanceInfo?.currency}}</div>
      <div class="add-icon" fxLayoutAlign="center center" fxLayout="row">
        <img src="{{licenceService.getKey('icon-add')}}" />
      </div>
    </div>
  </div>
</ng-template>