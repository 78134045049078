import { Apollo } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { map, switchMap, take } from 'rxjs/operators';
import { FRONT_PAGE_GAMES_DESKTOP_QUERY, FRONT_PAGE_GAMES_QUERY } from '../../apollo/front-page-games/front-page-games';
import { FrontPageGamesResponse } from '../../apollo/models/base-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { countryFromLocale } from 'src/app/utils/country-from-locale';
import { LICENCE } from 'src/app/app-routing.module';
import { DetectDeviceService } from '../utils/detect-device.service';
import { ClonerService } from '../utils/clone-object.service';

@Injectable({
    providedIn: 'root'
})
export class FrontPageGamesService {

    constructor(
        private apollo: Apollo,
        public detectDeviceService: DetectDeviceService,
        private cloneService: ClonerService,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(LICENCE) public licence: string,
    ) { }

    private _response = new BehaviorSubject<FrontPageGamesResponse>(null);


    fetchData(): Observable<FrontPageGamesResponse> {
        if (this._response.value) {
            return this._response.asObservable();
        }
        else {
            const query = this.detectDeviceService.isDesktop() ? FRONT_PAGE_GAMES_DESKTOP_QUERY : FRONT_PAGE_GAMES_QUERY;
            return this.apollo
                .watchQuery<FrontPageGamesResponse>({
                    query: query,
                    variables: {
                        licenseName: this.licence,
                        locale: this.locale,
                        countryName: [countryFromLocale(this.locale)]
                    }
                })
                .valueChanges.pipe(
                    take(1),
                    map(resp => {
                        let frontPageGames = this.cloneService.deepClone<FrontPageGamesResponse>(resp.data);
                        frontPageGames.pagesCategories = frontPageGames.pagesCategories.filter(item => !!item.licence.find(licence => licence === this.licence) || !!item.licence.find(licence => licence === 'all'))
                        return frontPageGames
                    }),
                    switchMap((response) => {
                        this._response.next(response);
                        return this._response.asObservable();
                    }
                    ),
                );
        }
    }
}
