<div fxLayoutAdivgn="center center">
    <div class="page-body" *ngIf="!isNotFound else nosite" [ngClass]="{'html-template-page': isHtml, 'desktop': deviceDetector.isDesktop()}">
        <ng-container *ngIf="pageJson?.headerBanner?.url">
            <div class="top-image">
                <!-- <img [src]="pageJson?.headerBanner?.url" alt="batch icon" class="batch-icon"/> -->
                <img [src]="pageJson?.headerBanner?.url" alt="header banner" />
            </div>
        </ng-container>
        <div class="content" [innerHTML]="body"></div>
    </div>
</div>
<ng-container *ngIf="standalone">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #nosite>
    <app-mobile-not-found></app-mobile-not-found>
</ng-template>

<ng-template #regularBanner>
    <div *ngIf="!isHtml" class="top-image">
        <img [src]="pageJson?.headerBanner?.url" alt="header banner" />
    </div>
</ng-template>