import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import {
  CMSTournamentDescription,
  MappedTournamentRank,
  TournamentDescription,
  TournamentFeed,
  TournamentPoints,
  TournamentPrizes,
  TournamentState
} from './tournaments.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { map, mergeMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { LicenceService } from '../utils/licence.service';

const prizesQuery = gql`query TournamentsList($locale: Locale!,$searchRef: ID!){
  tournament(locales: [$locale, en],where: {id: $searchRef}) {
    name
    tournamentPrizes{
      name
      position
    }
  }
  }
  `

const pointsQuery = gql`query TournamentPoint($locale: Locale!,$searchRef: ID!){
  tournament(locales: [$locale, en],where: {id: $searchRef}) {
    name
    tournamentPoints{
      name
      value
      points
      pointsType
    }
  }
  }
  `

const descriptionQuery = gql`query TournamentDescription($locale: Locale!,$searchRef: ID!){
  tournament(locales: [$locale, en],where: {id: $searchRef}) {
    description{
      html
    }
  }
  }
  `
const networkDescriptionQuery = gql`query TournamentNetworkDescription($locale: Locale!,$searchRef: ID!){
  tournamentNetwork(locales: [$locale, en],where: {id: $searchRef}) {
    description{
      html
    }
  }
  }
  `

@Injectable({
  providedIn: 'root'
})
export class TournamentDetailsService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private apollo: Apollo,
    private loginStatus: LoginStatusService,
    private licenceConfigService: LicenceService,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) private locale,
    private sanitizer: DomSanitizer
  ) {
    super(http, afAuth, afs, hostName);
  }

  private _feedStore: { [ref: string]: BehaviorSubject<TournamentFeed> } = {};


  public getTournamentPoints(ref: string): Observable<TournamentPoints[]> {
    return this.apollo
      .watchQuery<{ tournament: { tournamentPoints: TournamentPoints[] } }>({
        query: pointsQuery,
        variables: {
          searchRef: ref,
          locale: this.locale
        }
      })
      .valueChanges.pipe(
        map(item => item.data.tournament.tournamentPoints)
      )
  }

  private getTournamentFeed(ref: string): Observable<TournamentFeed> {
    if (ref in this._feedStore) {
      return this._feedStore[ref];

    } else {
      return this.getDocumentDB<TournamentFeed>(`tournaments/${ref}`).pipe(
        mergeMap((data: TournamentFeed) => {
          if (ref in this._feedStore) {
            this._feedStore[ref].next(data);
          }
          else {
            this._feedStore[ref] = new BehaviorSubject<TournamentFeed>(data);
          }
          return this._feedStore[ref]
        })
      );
    }
  }

  public getTournamentState(ref: string): Observable<TournamentState> {
    return this.getTournamentFeed(ref).pipe(
    )
  }

  public getTournamentRank(ref: string): Observable<MappedTournamentRank> {
    return combineLatest
      (
        [
          this.loginStatus.getUserState(),
          this.getTournamentFeed(ref).pipe(
            map(item => item.rank)
          )
        ]
      ).pipe(
        map(([loginStatus, tournamentRank]) => {
          const tournamentPlayerId = loginStatus?.dbUser?.tournamentPlayerId;
          const nickname = loginStatus?.dbUser?.nickname;
          let index = -1;
          if (tournamentPlayerId) {
            index = tournamentRank.findIndex(item => item.tournamentPlayerId === tournamentPlayerId);
            if (index > -1) tournamentRank[index].nickname = nickname;
          }
          return {
            rank: tournamentRank,
            myPosition: index
          }
        })
      )
  }

  public getTournamentPrizes(ref: string): Observable<TournamentPrizes[]> {
    return this.apollo
      .watchQuery<{ tournament: { tournamentPrizes: TournamentPrizes[] } }>({
        query: prizesQuery,
        variables: {
          searchRef: ref,
          locale: this.locale
        }
      })
      .valueChanges.pipe(
        map(item => item.data?.tournament?.tournamentPrizes)
      )
  }

  public getTournamentDescription(ref: string): Observable<TournamentDescription> {
    return this.apollo
      .watchQuery<{ tournament: CMSTournamentDescription }>({
        query: descriptionQuery,
        variables: {
          searchRef: ref,
          locale: this.locale
        }
      })
      .valueChanges.pipe(
        map(item => item.data.tournament.description.html),
        map(item => item.replaceAll("{CasinoName}", this.licenceConfigService.getKey("name")).replaceAll("{Hostname}", this.hostName)),
        map(item => {
          const description = this.sanitizer.bypassSecurityTrustHtml(item)
          return { description } as TournamentDescription
        })
      )
  }
  public getNetworkTournamentDescription(ref: string): Observable<TournamentDescription> {
    return this.apollo
      .watchQuery<{ tournamentNetwork: CMSTournamentDescription }>({
        query: networkDescriptionQuery,
        variables: {
          searchRef: ref,
          locale: this.locale
        }
      })
      .valueChanges.pipe(
        map(item => item.data.tournamentNetwork.description.html),
        map(item => {
          const description = this.sanitizer.bypassSecurityTrustHtml(item)
          return { description } as TournamentDescription
        })
      )
  }
}
