import { UserGamificationLevel } from './../../../services/profile/profile.models';
import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { delay, distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import * as PixiSpine from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { isPlatformServer } from '@angular/common';
import { Observable, Subject, concat, interval, of, timer } from 'rxjs';
import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { log } from 'console';

const ANIMATION_CANVAS_SIZE_HEIGHT = 78;
const ANIMATION_CANVAS_SIZE_WIDTH = 78;
const ANIMATION_SIZE_WIDTH = 140;
const ANIMATION_NAME = 'convert';
const ICON_ANIMATION_NAME = 'icon-playbison-level-';

const PATH_TO_ANIMATION_JSON = 'assets/animations/progress_meter/progress_meter.json';

@Component({
  selector: 'app-gamification-progress',
  templateUrl: './gamification-progress.component.html',
  styleUrls: ['./gamification-progress.component.scss']
})
export class GamificationProgressComponent extends BasePageComponent {

  app: PIXI.Application;
  canvasHeight: number;
  isServer: boolean;
  progressPercentage$: Observable<number>;
  source = interval(100);

  @ViewChild('pixiAnimation') pixiAnimation: ElementRef;

  private animationSize = {
    width: ANIMATION_CANVAS_SIZE_WIDTH,
    height: ANIMATION_CANVAS_SIZE_HEIGHT,
    scale: ANIMATION_CANVAS_SIZE_WIDTH / ANIMATION_SIZE_WIDTH,
  };

  isHidden: boolean = false;
  gamificationIndexSubject = new Subject<number>();
  gamificationIndex$ = this.gamificationIndexSubject.asObservable();
  prevGamification: UserGamificationLevel;
  currentGamification: UserGamificationLevel;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    protected translationConfig: TranslationConfig,
    protected minimizeService: MinimizeSiteService,
    private gamificationLevelService: GamificationLevelService,
    @Inject(PLATFORM_ID) private platformId,
    private ngZone: NgZone,
  ) { super() }


  ngOnInit(): void {
    console.log('GamificationProgressComponent INIT');

    this.canvasHeight = this.animationSize.height;
    this.isServer = isPlatformServer(this.platformId);

    this.minimizeService.isToolbarNotMinimized().pipe(takeUntil(this.unsubscribe)).subscribe((isNotMinimized) => {
      this.isHidden = !isNotMinimized;
    })

    this.prevGamification = this.gamificationLevelService.getArchivedValue();
    this.currentGamification = this.gamificationLevelService.getCurrentValue();
    this.gamificationIndex$ = of(this.prevGamification?.levelNumber);
  }

  ngAfterViewInit() {
    if (!this.isServer) {
      this.ngZone.runOutsideAngular(() => {
        timer(0, 100).pipe(
          switchMap(() => this.initializePixi()),
          takeUntil(this.unsubscribe)
        ).subscribe();
      });

      let levelDifference = this.currentGamification?.levelNumber - this.prevGamification?.levelNumber;
      let observables = [];

      observables.push(
        this.source.pipe(
          take(1),
          map(value => Math.round(this.prevGamification?.turnover / this.prevGamification?.levelEndsTurnover * 100))
        )
      );

      for (let index = 0; index <= levelDifference; index++) {
        let gamificationIndex = this.prevGamification?.levelNumber + index;
        let percentage = levelDifference > 0 && index < levelDifference ? 100 : Math.round(this.currentGamification?.turnover / this.currentGamification?.levelEndsTurnover * 100);
        let startPosition = index === 0 ? Math.round(this.prevGamification?.turnover / this.prevGamification?.levelEndsTurnover * 100) : 0;

        observables.push(
          this.source.pipe(
            take(percentage - startPosition),
            map(value => startPosition + value + 1),
            delay(300),
            tap(() => {
              this.gamificationIndex$ = of(gamificationIndex)
              this.gamificationIndexSubject.next(gamificationIndex);
            })
          )
        );
      }

      observables.push(
        this.source.pipe(
          take(1),
          delay(3000),
          tap(() => {
            this.gamificationLevelService.cleanArchivedValue();
          })
        )
      );

      this.progressPercentage$ = concat(...observables) as Observable<number>;
      this.cdr.detectChanges();
    }
  }

  initializePixi(): Observable<void> {
    if (this.pixiAnimation && this.pixiAnimation.nativeElement) {
      this.app = new PIXI.Application({
        preserveDrawingBuffer: true,
        backgroundAlpha: 0,
        width: this.animationSize.width,
        height: this.animationSize.height,
      });

      PIXI.Assets.load(PATH_TO_ANIMATION_JSON).then(this.onAssetsLoaded);

      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
    return of(null);
  }

  onAssetsLoaded = (levelUpAsset) => {
    this.gamificationIndexSubject.pipe(
      distinctUntilChanged()
    ).subscribe((resp) => {
      this.app.stage.removeChildren();
      const iconSpine = new PixiSpine.Spine(levelUpAsset.spineData);

      iconSpine.state.data.skeletonData.bones;
      iconSpine.scale.x = Math.round(this.animationSize.scale * 100) / 100;
      iconSpine.scale.y = Math.round(this.animationSize.scale * 100) / 100;
      iconSpine.x = this.animationSize.width / 2;
      iconSpine.y = this.animationSize.height / 2;
      iconSpine.state.timeScale = 0.6;

      iconSpine.state.setAnimation(0, ICON_ANIMATION_NAME + resp, true);

      iconSpine.interactive = true;
      if (this.app && this.app.stage) {
        this.app.stage.addChild(iconSpine);
      }

      if (resp != this.prevGamification?.levelNumber) {
        const levelUpSpine = new PixiSpine.Spine(levelUpAsset.spineData);

        levelUpSpine.state.data.skeletonData.bones;
        levelUpSpine.scale.x = Math.round(this.animationSize.scale * 100) / 100;
        levelUpSpine.scale.y = Math.round(this.animationSize.scale * 100) / 100;
        levelUpSpine.x = this.animationSize.width / 2;
        levelUpSpine.y = this.animationSize.height / 2;
        levelUpSpine.state.timeScale = 0.6;

        levelUpSpine.state.setAnimation(0, ANIMATION_NAME, false);
        if (this.app && this.app.stage) {
          this.app.stage.addChild(levelUpSpine);
        }

        levelUpSpine.interactive = true;
      }
      this.app.stage.interactive = true;

      if (this.pixiAnimation && this.pixiAnimation.nativeElement && this.app && this.app.view) {
        this.pixiAnimation.nativeElement.appendChild(this.app.view);
      }
    });
  };

  redirectToProfile(): void {
    this.router.navigate(['', 'client-area', 'main']);
  }

}
