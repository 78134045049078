<div class="content-position">
    <div class="center-wrapper" *ngIf="!deviceDetect.isDesktop() else desktop" [ngClass]="{'desktop':deviceDetect.isDesktop()}" fxLayoutAlign="center center">
        <div class="page-wrapper" fxLayoutAlign="space-around center" fxLayout="column">
            <div class="page-wrapper" fxLayoutAlign="space-around center" fxLayout="column">
                <!-- <div class="header-wrapper" fxLayoutAlign="center center" fxLayout="column">
                    <div class="header" i18n>Spin the reel to WIN!</div>
                    <div class="description" i18n>Amazing rewards are hidden on scratch cards.</div>
                </div> -->
                <div class="left-spins-info" fxLayoutAlign="center center">
                    <ng-container *ngIf="leftToOpen"><span i18n>Spins left:</span>&nbsp;<span class="spins-value">{{leftToOpen}}</span></ng-container>
                </div>
                <div class="wheel">
                    <img src="assets/playbison/spin/bison-_pose_4_final.png" />
                    <div class="slots" #slotMachine></div>
                    <ng-container *ngIf="isStartWinAnimation; then desktopwinpresentation"></ng-container>
                    <canvas class="confetti-canvas" #confetticanvas></canvas>
                </div>
                <div class="footer-wrapper" fxLayoutAlign="center center" fxLayout="column">
                    <div class="spin-button-wrapper">
                        <button *ngIf="!isFinished" round-button filled="filled" color="accent" class="lemon-default-btn wheel-btn" [ngClass]="{'inactive': !isActive}" (click)="spinWheel()" i18n>Spin Now!</button>
                        <button *ngIf="isFinished" round-button filled="filled" color="accent" class="lemon-default-btn wheel-btn" [ngClass]="{'inactive': !isActive}" (click)="closeDialog()" i18n>My bonuses</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #desktop>
    <div class="center-wrapper-desktop">
        <div class="page-wrapper" fxLayoutAlign="space-around center" fxLayout="column">
            <div class="header-wrapper" fxLayoutAlign="center center" fxLayout="column">
                <!-- <div class="header" i18n>Spin the reel to WIN!</div>
                <div class="description" i18n>Amazing rewards are hidden on scratch cards.</div> -->

            </div>
            <div class="left-spins-info" fxLayoutAlign="center center">
                <ng-container *ngIf="leftToOpen"><span i18n>Spins left:</span>&nbsp;<span class="spins-value">{{leftToOpen}}</span></ng-container>
            </div>
            <div class="wheel">
                <img src="assets/playbison/spin/bison-_pose_4_final.png" />
                <div class="slots" #slotMachine></div>
                <ng-container *ngIf="isStartWinAnimation; then desktopwinpresentation"></ng-container>
                <canvas class="confetti-canvas" #confetticanvas></canvas>
                <div class="spin-button-wrapper">
                    <button *ngIf="!isFinished" round-button filled="filled" color="accent" class="lemon-default-btn wheel-btn" [ngClass]="{'inactive': !isActive}" (click)="spinWheel()" i18n>Spin Now!</button>
                    <button *ngIf="isFinished" round-button filled="filled" color="accent" class="lemon-default-btn wheel-btn" [ngClass]="{'inactive': !isActive}" (click)="closeDialog()" i18n>My bonuses</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #desktopwinpresentation>
    <div class="win-box-overlay" (click)="overlayClicked()">
        <div class="win-box">
            <div class="win-box-wrapper" @fadeIn fxLayoutAlign="space center" fxLayout="column">
                <div class="win-info" i18n>You have WON!</div>
                <div class="win-value">
                    <div class="value">{{prizeValue}}</div>
                    <div class="name">{{prizeName}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>